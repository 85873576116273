.list__package {
    display: flex;
    align-items: flex-start;
    margin: 26px -5px 0px;
    flex-basis: auto;
    font-family: 'Poppins', sans-serif;
    // font-family: 'Rubik', sans-serif;
}

.list__package-item {
    display: flex;
    flex-direction: column;
    margin: 10px 5px;
    min-height: 400px;
    height: auto;
    width: 25%;
    border-radius: 10px;
    background: rgb(244, 245, 247);
    transition: all 0.3s ease;

    &-header {
        text-align: center;
        padding: 13px 10px 17px;
        text-transform: uppercase;
        color: rgb(248, 248, 248);
        background: #020b74;
        border-radius: 5px;
        font-size: 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    ul {
        list-style-type: disc;
        font-weight: 500;
        margin: 0;
        padding-left: 40px;
        margin-top: 1em;

        li {
            margin-top: 1em;
            padding-right: 0.4em;
        }
    }

    span {
        margin-top: auto;
        font-weight: 700;
        font-size: large;
        color: #030e82;
        text-align: center;
    }

    button {
        background: #020b74;
        padding: 5px 10px;
        border-radius: 5px;
        color: #fff;
        font-weight: 500;
        margin-top: 10px;
    }
}


.qrcode {
    width: 50%;
    float: left;
}

.information {
    width: 50%;
    float: right;
    padding-left: 10px;
    padding-right: 10px;
}
