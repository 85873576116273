.list__board {
    @include flex(center, flex-start);
    margin: 26px -5px 0px;
    flex-basis: auto;
    &-item {
        display: flex;
        flex-direction: column;
        margin: 10px 5px;
        min-height: 400px;
        height: auto;
        width: 25%;
        border-radius: 10px;
        background: rgb(244, 245, 247);
        transition: all 0.3s ease;
        &-header {
            padding: 13px 10px 17px;
            text-transform: uppercase;
            color: rgb(94, 108, 132);
            font-size: 12.5px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    &-group {
        max-height: 500px;
        padding: 0 10px 10px;
        overflow: auto;
    }
}
