.wrapper{
    @include flex(flex-end,center);
    padding-bottom: 30px; 
    flex-direction: column;
    height: 100vh;
    background: linear-gradient();
    background-repeat: no-repeat;
    background-position: center top;
    color: $white;
    h1{
        font-size: 3rem;
    }
    .message{
        font-size: 1.5rem;
        padding: 20px;
        text-align: center;
        width: 60%;
    }
    .btn {
        background: rgb(88, 0, 202);
        text-decoration: none;
        font-size: 1.5rem;
        border-radius: 10px;
        padding: 12px;
        color: rgb(255, 255, 255);
        cursor: pointer;
    }
    .btn:hover {background: rgb(132, 37, 255) ;}
    .copyRights {
        margin-top: 60px;
    }
}