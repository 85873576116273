.user {
    overflow: hidden;
    height: 100vh;
    background: #020b74;
    &__left{
        @include flex(center,center);
        padding: 2rem 0 2rem 2rem;
        @include tablet{
            display: none;
        }
    }
    &__right{
        color: $white;
        font-family: 'Candal', sans-serif;
        font-weight: 500;
    }
}