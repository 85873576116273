$font-family: Arial, Helvetica, sans-serif;

$white: #ffffff;
$white-100: rgba(255, 255, 255, 0.1);
$white-200: rgb(235, 236, 240);
$white-700: rgb(222, 235, 255);
$black: #000000;
$black-400:rgb(94, 108, 132);
$black-500: rgb(66, 82, 110);
$blue-600: rgb(0, 82, 204);
$blue-700: rgb(7, 71, 166);
$gray-100: #f4f5f7;

$box-shadow: rgb(0 0 0 / 60%) 0px 0px 50px 0px;

$fs-14: 14px;
$fs-22: 22px;
$fs-40: 40px;

$tablet-width: 1024px;
$desktop-width: 1275px;

$fw-500: 500;
$fw-bold: bold;