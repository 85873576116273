.appChatbotButton {
    background-color: #1f3646;
    border: none;
    border-radius: 25px;
    bottom: 15px;
    height: 50px;
    position: fixed;
    right: 1px;
    width: 50px;
    z-index: 6
}

.appChatbotContainer {
    position: relative;
    height: 600px;
    width: 400px;
    border-radius: 5px;
    bottom: 40px;
    box-shadow: 5px 5px 13px rgba(91, 81, 81, .4);
    justify-content: center;
    margin: 40px 0;
    position: fixed;
    right: 20px;
    z-index: 0
}

.appChatbotMessage {
    font-size: 19px;
}

.appChatbotMessageInput {
    width: "400px";
    height: "70px";
    font-size: "19px"
}

.appChatbotButtonIcon {
    fill: #fff;
    height: 25px;
    width: 25px
}
