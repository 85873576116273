.menu {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 64px;
    height: 100vh;
    width: 240px;
    //width: 230px;
    padding: 0px 16px 24px;
    background: $gray-100;
    border-right: 1px solid rgb(223, 225, 230);
    overflow: hidden auto;
    &__user {
        @include flex(center, center);
        padding: 24px 4px;
        img {
            width: 80px;
            border: 1px solid $black;
            border-radius: 50%;
        }
        &-info {
            padding: 3px 0px 0px 10px;
            .framework {
                color: $black-500;
                font-size: 15px;
                font-weight: $fw-bold;
            }
            .project {
                color: $black-400;
                font-size: $fs-14;
                font-weight: $fw-bold;
            }
        }
    }
    &__item {
        position: relative;
        @include flex(flex-start, center);
        padding: 8px 12px;
        border-radius: 3px;
        cursor: pointer;
        user-select: none;
        color: $black;
        // font-family: 'Poppins', sans-serif;
        font-family: 'Rubik', sans-serif;
        &.active {
            color: $blue-600;
            background: $white-200;
            .menu__item-icon {
                color: $blue-600;
            }
        }
        &:hover {
            background-color: $white-200;
            .implemente {
                opacity: 1;
            }
        }
        &-icon {
            margin-right: 15px;
            font-size: 20px;
        }
        span {
            padding-top: 2px;
            font-size: 14.7px;
        }
        .implemente {
            display: inline-block;
            position: absolute;
            top: 7;
            left: 40px;
            width: 140px;
            padding: 5px 0px 5px 8px;
            border-radius: 3px;
            text-transform: uppercase;
            color: rgb(66, 82, 110);
            background: rgb(223, 225, 230);
            opacity: 0;
            font-size: 11.5px;
            font-weight: $fw-bold;
        }
    }
    @include desktop {
        width: 100px;
        &__user-info {
            display: none;
        }
        &__item {
            justify-content: center;
            span {
                display: none;
            }
        }
    }
    @include tablet {
        display: none;
    }
}
