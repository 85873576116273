.cards {
    display: block;
    margin-bottom: 5px;
    padding: 10px;
    border-radius: 3px;
    background: rgb(255, 255, 255);
    box-shadow: rgb(9 30 66 / 25%) 0px 1px 2px 0px;
    transition: all 0.3s ease 0s;
    cursor: grabbing !important;
    user-select: none;
    &:hover {
        background: rgb(235, 236, 240);
    }
    p {
        padding-bottom: 11px;
        font-size: 15px;
        font-weight: bold;
        text-transform: uppercase;
        margin: 0;
    }
    &__user {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-wrap: wrap;
        .priority {
            font-weight: bold;
            font-size: 20px;
            margin-top: 10px;
            display: inline-block;
        }
        &-icons {
            display: flex;
            align-items: center;
            margin-right: auto;
            margin-top: 10px;
            .icon {
                width: 24px;
                height: 24px;
                line-height: 34px;
            }
        }
        // @include tablet {
        //     .priority {
        //         margin-top: 10px;
        //     }
        // }
        @include desktop {
            &-icons {
                display: none;
            }
        }
    }
}
