.user__pages{
    width: 100%;
    height: 100%;
    @include flex(center,center);
    flex-direction: column;
    &-title{
        font-size: $fs-40;
    }
    &-form{
        width: 50%;
        text-align: center;
    }
}