.sidebar {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0px;
    overflow-x: hidden;
    height: 100vh;
    width: 64px;
    background: #020b74;
    transition: all 0.1s ease 0s;
    transform: translateZ(0px);
    &:hover {
        width: 200px;
        box-shadow: $box-shadow;
        .sidebar-logo{
            text-align: center;
        }
        .title {
            display: block;
        }
    }
    &-logo {
        display: block;
        position: relative;
        text-align: left;
        margin: 20px 0px 10px;
        transition: all 0.1s ease 0s;
        & > a {
            color: $white;
            font-size: $fs-40;
            display: inline-block;
            padding: 10px;
            cursor: pointer;
            user-select: none;
        }
    }
    &__item {
        // @include flex(left,left);
        // margin-left: 20px;
        @include flex(center,center);
        width: 100%;
        height: 42px;
        color: $white-700;
        transition: color 0.1s ease 0s;
        cursor: pointer;
        user-select: none;
        &-icon {
            display: inline-block;
            font-size: $fs-22;
        }
        &:hover{
            background-color: $white-100;
        }
    }
    .title {
        display: none;
        margin-left: 10px;
        text-transform: uppercase;
        font-weight: normal;
        font-family: 'Poppins', sans-serif;
        // font-family: 'Rubik', sans-serif;
        font-size: 14px;
        transition: right 0.1s ease 0s, visibility, opacity;
    }
    &__bottom{
        position: absolute;
        bottom: 20px;
        left: 0px;
        width: 100%;
    }
}
